
/* --- SLIDER --- */
module.exports = () => {
	jQuery(document).ready(function () {

		jQuery('.slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.slider').slick('resize'); // recalc on window resize
		});


		jQuery('.sectors-flex').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1.2,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: false,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1000,
					settings: "unslick"
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.sectors-flex').slick('resize'); // recalc on window resize
		});


		jQuery('.image_slick').slick({
			centerMode: false,
			centerPadding: "50px",
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.sectors-flex').slick('resize'); // recalc on window resize
		});




		jQuery('.case-studies-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			swipeToSlide: true,
		});
		jQuery(window).resize(function(){
			jQuery('.case-studies-slider').slick('resize'); // recalc on window resize
		});


		jQuery('.hero_heading .accreditations').slick({
			speed: 9000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1000,
					settings: "unslick"
				}
			]
		}); 
		jQuery(window).resize(function(){
			jQuery('.hero_heading .accreditations').slick('resize'); // recalc on window resize
		});


	});
}