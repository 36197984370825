
module.exports = () => {
    jQuery(document).ready(function() {

        // expand paragraph and spin arrow when clicking title
        let pointTitles = document.querySelectorAll('.point .title');
        for (let i=0; i<pointTitles.length; i++) {

            let title = pointTitles[i];
            title.addEventListener('click', function() {

                let paragraph = this.nextElementSibling;
                let parent = this.parentElement;

                // if closing the title
                if (parent.classList.contains('open')) {
                    parent.classList.remove('open');
                    let height = '0px';
                    paragraph.style.maxHeight = height;
                }
                // if opening the title 
                else {
                    // first close all others
                    for (let j=0; j<pointTitles.length; j++) {
                        pointTitles[j].parentElement.classList.remove('open');
                        pointTitles[j].nextElementSibling.style.maxHeight = "0px";
                    }

                    parent.classList.add('open');
                    let height = paragraph.scrollHeight + 25 + 'px';
                    paragraph.style.maxHeight = height;
                }
            })
        }


        // Auto-expand first title on page load
        let pointLayouts = document.querySelectorAll('.points-container');
        for (let i=0; i<pointLayouts.length; i++) {
            let layout = pointLayouts[i];
            let first = layout.querySelector('.point');
            let paragraph = first.querySelector('.paragraph');

            first.classList.add('open');
            let height = paragraph.scrollHeight + 25 + 'px';
            paragraph.style.maxHeight = height;
        }
    })
}