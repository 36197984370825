module.exports = () => {
    jQuery(document).ready(function() {

        // Open search menu with search button
        let searchButton = document.querySelectorAll('.header .search-button')[0];
        let searchBar = document.querySelectorAll('.header .search-bar')[0];
        let searchInput = searchBar.querySelector('input');
        searchButton.addEventListener('click', function() {
    
            if (searchBar.classList.contains('search-bar-visible')) {
                searchBar.classList.remove('search-bar-visible');
                searchButton.classList.remove('search-button-visible');
            } else {
                searchBar.classList.add('search-bar-visible');     
                searchButton.classList.add('search-button-visible');
                let searchInput = document.querySelectorAll('.search-form .search-field')[0]
                searchInput.focus();
                searchInput.setSelectionRange(1000, 1000);
            }
            
        });
        // close search if clicking off mouse
        document.addEventListener('click', function(e) {
            if (!(e.target === searchBar || e.target === searchButton || e.target === searchInput )) {
                searchBar.classList.remove('search-bar-visible');
                searchButton.classList.remove('search-button-visible');
            }
        });
    });
    
}